import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useLocalStorage } from '@vueuse/core'

export const useNotificationPermissionsStore = defineStore('permissions', () => {
    const showModalPermissions = ref(false)
    const permissions = ref(useLocalStorage('permissions', {
        notificationPermission: false,
        soundPermission: false,
        soundVolume: 1
    }))

    function modalPermissions() {
        showModalPermissions.value = !showModalPermissions.value
    }

    return { modalPermissions, showModalPermissions, permissions }
})