import { defineStore } from 'pinia'
import { ref, watch } from 'vue'
import { useLocalStorage } from '@vueuse/core'
import { useNotificationPermissionsStore } from './NotificationPermissionsStore.js'
import { httpRequests } from '../helpers/HttpRequests.js'
import { useRouter } from 'vue-router'
import { panicAlertEvent } from '../sockets-events/PanicAlertEvent.js'
import { useClientStore } from './ClientStore.js'

export const usePanicAlertStore = defineStore('panicAlert', () => {
    const { permissions } = useNotificationPermissionsStore()
    const { joinPanicAlerEvent } = panicAlertEvent()
    const { getRequest } = httpRequests()
    const router = useRouter()
    const alert = ref(useLocalStorage('alert', { panicAlertStatus: false, associates: [], pending: 0 }));
    const filesPath = process.env.VUE_APP_FILES_PATH
    const soundNotification = new Audio(require('@/assets/sounds/panic-alert.wav'))
    const { client } = useClientStore()

    watch(() => client.isAuth, (isAuth) => {
        if (isAuth) getTotalPendingAlerts()
    })

    // * Join into panic alert event
    function joinPanicAlertEvent() {
        joinPanicAlerEvent()
            .listen(".panic-alert-events", async (associate) => {
                // * Validate if is a panic alert enabled
                if (associate.alertStatusId === 4) {
                    // * Add associate in associates array
                    alert.value.associates.push(associate)

                    // * Validate if notifications api have permissions
                    if (Notification.permission === 'granted') setNotificationAlert(associate)

                    // * Redirect to alerts view
                    router.push('/associates_alert')
                }

                // * Validate if is a panic alert is disable
                if (associate.alertStatusId === 6) {
                    const alertIndex = alert.value.associates.findIndex(associateAlert => associateAlert.alertId === associate.alertId)

                    alert.value.associates.splice(alertIndex, 1)
                }

                // * Change status of panic alert
                alert.value.panicAlertStatus = alert.value.associates.length ? true : false

                // * Redirect to home if not have a panic alert
                if (!alert.value.associates.length) {
                    // * Set selected view AlertsLogs
                    localStorage.setItem("selectedView", JSON.stringify({ view: 'AlertsLogs' }))

                    // * Refresh total alerts in pending status
                    getTotalPendingAlerts()

                    // * Pausa sound notification
                    soundNotification.pause()

                    // * Redirect to root /
                    router.push('/')
                }
            })
    }

    // * Check panic alert status
    async function checkPanicAlertStatus() {
        const response = await getRequest('/panic_alert_status')
        alert.value.panicAlertStatus = response.data

        if (alert.value.panicAlertStatus) await getPanicAssociatesList()
        else alert.value.associates = []
    }

    // * Get panic alert associates list
    async function getPanicAssociatesList() {
        const response = await getRequest('/web/alerts_logs/associates')
        alert.value.associates = response.data
    }

    // * Show alert notification
    function setNotificationAlert(associate) {
        // * Set config notification
        const notificationTitle = `SafeHub Alert: ${associate.associateName}`
        const notificationOptions = {
            body: `Tap To View Location`,
            icon: `${filesPath}${associate.associateImage}`,
            requireInteraction: true
        }

        // * Show notification
        const notification = new Notification(notificationTitle, notificationOptions)

        // * play custom notification sound
        soundNotification.loop = true
        soundNotification.volume = permissions.soundVolume
        soundNotification.pause()

        soundNotification.play()

        // * Open live tracking tab in browser
        notification.addEventListener('click', () => {
            window.open(`https://safehub.prosafe.app/alert_timeline/${associate.alertId}`, '_blank')
        })

        // * Open live tracking tab in browser
        notification.addEventListener('close', () => {
            soundNotification.pause()
        })

        // * Show console error if notification have a error
        notification.addEventListener('error', (error) => {
            console.error(`Error showing alert notification: ${error}`)
        })
    }

    // * Get total alerts in pending status
    async function getTotalPendingAlerts() {
        const response = await getRequest('/web/alerts_logs/peding_alerts')

        if (response.hasErrors) return console.error(`Error getting total pending alerts: ${response.message}`)

        alert.value.pending = response.data
    }

    return { getPanicAssociatesList, checkPanicAlertStatus, joinPanicAlertEvent, getTotalPendingAlerts, alert }
})