<template>
  <div>
    <router-view />
  </div>

  <!-- Associates alert notification notch -->
  <NotchAlertNotification v-if="client.isAuth && alert.panicAlertStatus" />
</template>

<script setup>
import NotchAlertNotification from '@/common/NotchAlertNotification.vue'
import { usePanicAlertStore } from '@/stores/PanicAlertStore.js'
import { useClientStore } from '@/stores/ClientStore'
import { useSubscriptionsStore } from '@/stores/SubscriptionStore.js'
import { stripeSubscriptionEvent } from '@/sockets-events/StripeSubscriptionEvent.js'
import { watch, onMounted } from 'vue'

onMounted(() => {
  // * Check subscription status when customer open a new tab witch SafeHub
  document.addEventListener("visibilitychange", async () => {
    if (!document.hidden && client.isAuth) await loginStatus()
    if (!document.hidden && client.isAuth) checkProsafeLicenceStatus()
  })
})

// * Subscriptions store
const { checkProsafeLicenceStatus } =  useSubscriptionsStore()

// * Panic Alert Store
const { alert, checkPanicAlertStatus, joinPanicAlertEvent } = usePanicAlertStore()

// * Client Store
const { client, loginStatus } = useClientStore()

// * Subscription events
const { joinStripeSubscriptionEvent } = stripeSubscriptionEvent()

// * Join into customer subscription event
if (client.id) joinStripeSubscriptionEvent()

// * Join into panic alert event
if (client.hotelId) joinPanicAlertEvent()

// * Check Panic Alert Status
if (client.hotelId) checkPanicAlertStatus()

// * Detect changes in client obj
watch(client, () => {
  // * Join into customer subscription event
  if (client.id) joinStripeSubscriptionEvent()

  // * Join into panic alert event
  if (client.hotelId) joinPanicAlertEvent()

  // * Check Panic Alert Status
  if (client.hotelId) checkPanicAlertStatus()
})
</script>

<style lang="scss">
@import '/src/assets/scss/main.scss';
</style>
